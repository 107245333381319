import React, {Component} from "react";
import NavBar from '../components/navbar'
import Header from '../components/header';
import Services from '../components/services';
import Livraison from '../components/livraison';
import Contact from '../components/contact';
import Footer from "../components/footer";

class HomePage extends Component {
    render() {
        return (
            <div className="App">
                <NavBar/>
                <Header/>
                <Services/>
                <Livraison/>
                <div class="container-fluid">
                    <div class="row" style={{paddingTop: "1.5vw"}}>
                        <p style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "1.2vw"}}>
                        Ensemble nous étudions vos attentes, vos goûts, vos préférences et contraintes alimentaires.<br/>
                        Vous choisissez la formule la mieux adaptée (déjeuner et/ou collation du soir).<br/>
                        Vous déterminez les jours où vous souhaitez être livrés. Nous vous proposerons une solution de livraison adaptée.
                        </p>
                    </div>
                    <div id="scrollspyContact" style={{marginTop: "2vw"}} class="row justify-content-space-around">
                        <Contact/>
                        <div class="col-6">
                            <h2 style={{color: "#1B7033", fontFamily: "raleway", fontSize: "2.5vw"}}>Où nous trouver ?</h2>
                            <div class="row justify-content-center">
                                <img alt="map" style={{ width: "35vw"}} class="img-fluid" src="/map.png"/>
                            </div>
                            <div class="row" style={{ marginTop: "2vw", height: "25vw"}}>
                                <div class="col align-items-center" style={{textAlign: "center"}}>
                                    <p style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "1.4vw", marginBottom: "unset"}}>
                                        Pour toute question, n’hésitez<br/>
                                        pas à nous contacter par<br/>
                                        téléphone, E-mail ou via notre<br/>
                                        formulaire de contact,<br/>
                                        réponse rapide assurée.<br/>
                                        <br/>
                                        Société agréée service à la<br/>
                                        personne, une déduction fiscale<br/>
                                        ou un crédit d’impot<br/>
                                        peut être mis en place.
                                     </p>
                                </div>
                                <div class="col" >
                                    <div class="row align-items-center justify-content-space-around" style={{textAlign: "start", marginBottom: "1.2vw", width: "unset", marginRight: "unset"}}>
                                        <img alt="facebook" style={{width: "4vw", minWidth: "35px"}} class="img-fluid" src="/facebook.png"/>
                                        <a href="https://www.facebook.com/Les-Lutins-Gourmets-106978435046092" rel="noreferrer" target="_blank" style={{marginBottom: "unset", fontSize: "1.2vw", color: "black"}}>/Les lutins gourmets</a>
                                    </div>
                                    <div class="row align-items-center justify-content-space-around" style={{textAlign: "start", marginBottom: "1.2vw", width: "unset", marginRight: "unset"}}>
                                        <img alt="insta" style={{width: "4vw", minWidth: "35px"}} class="img-fluid" src="/insta.png"/>
                                        <p style={{marginBottom: "unset", fontSize: "1.2vw"}}>/Les lutins gourmets</p>
                                    </div>
                                    <div class="row align-items-center justify-content-space-around" style={{textAlign: "start", marginBottom: "1.2vw", width: "unset", marginRight: "unset"}}>
                                        <img alt="mail" style={{width: "4vw", minWidth: "35px"}} class="img-fluid" src="/mail.png"/>
                                        <a href="mailto:fabienne@lutins-gourmets.com" style={{marginBottom: "unset", fontSize: "1.2vw", color: "black"}}>fabienne@lutins-gourmets.com</a>
                                    </div>
                                    <div class="row align-items-center justify-content-space-around" style={{textAlign: "start", marginBottom: "1.2vw", width: "unset", marginRight: "unset"}}>
                                        <img alt="phone" style={{width: "4vw", minWidth: "35px"}} class="img-fluid" src="/phone.png"/>
                                        <p style={{marginBottom: "unset", fontSize: "1.2vw"}}>04 50 77 16 60</p>
                                    </div>
                                    <div class="row align-items-center justify-content-space-around" style={{textAlign: "start", marginBottom: "1.2vw", width: "unset", marginRight: "unset"}}>
                                        <img alt="web" style={{width: "4vw", minWidth: "35px"}} class="img-fluid" src="/web.png"/>
                                        <p style={{marginBottom: "unset", fontSize: "1.2vw"}}>https://lutins-gourmets.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default HomePage;