import React from "react";
import NavBar from "../components/navbar";
import '../css/footer.css'

class repasPage extends React.Component {
    render() {
        return (
            <div>
                <NavBar/>
                <div class="container-fluid" style={{height: "100%"}}>
                    <div class="row" style={{padding: "1.5vw", textAlign: "center"}}>
                        <h1 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2.5vw", color: "#1B7033"}}>Le plaisir de <span style={{color: "#9999CC"}}>manger</span> au quotidien à votre <span style={{color: "#9999CC"}}>domicile</span><br/></h1>
                    </div>
                    <div class="row align-items-center" style={{justifyContent: "space-around", position: "relative"}}>
                        <img src="/repas_cut.jpg" style={{padding: "unset", position: "relative"}}/>
                        <div class="col-3" style={{backgroundColor: "#F6EDD9", padding: "1.2vw", borderRadius: "5px", position: "absolute", left: "6vw", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px"}}>
                            <p style={{fontFamily: "raleway", fontSize: "2vw", margin: "unset"}}>
                                Des repas sains et complets élaborés par notre diététicienne et notre chef.                                        
                            </p>
                        </div>
                        <div class="col-3" style={{backgroundColor: "#F6EDD9", padding: "1.2vw", borderRadius: "5px", position: "absolute", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px"}}>
                            <p style={{fontFamily: "raleway", fontSize: "2vw", margin: "unset"}}>
                                Des plats adaptés à votre régime (mixés, sans sel, diabétique).
                            </p>
                        </div>
                        <div class="col-3" style={{backgroundColor: "#F6EDD9", padding: "1.2vw", borderRadius: "5px", position: "absolute", left: "69vw", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px"}}>
                            <p style={{fontFamily: "raleway", fontSize: "2vw", margin: "unset"}}>
                                Des plats livrés froids se conservant au réfrigérateur.<br/>
                                Emballage recyclable.                            
                            </p>
                        </div>
                    </div>
                    <div class="row" style={{marginTop: "1.5vw", position: "relative", justifyContent: "space-around", placeItems: "center"}}>
                        <h2 style={{fontFamily: "raleway", fontSize: "3.5vw", color: "#1B7033", fontWeight: "bold", position: "relative"}}>Nos formules</h2>
                        <div style={{height: "3px", width: "72vw", backgroundColor: "#9999cc", borderRadius: "10px", position: "absolute", right: "1.5vw"}}/>
                    </div>
                    <div class="row" style={{justifyContent: "space-around", fontFamily: "raleway"}}>
                        <div class="col-2" style={{border: "3px solid #F6EDD9", borderRadius: "5px", textAlign: "center", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "20%"}}>
                            <img src="/logo_v3_vert.png" style={{width: "30%", marginTop: "1.3vw", marginBottom: "1.3vw"}}/>
                            <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>Déjeuner complet</h3>
                            <p style={{fontSize: "1.2vw"}}>
                                Entrée + plat + garniture +fromage + dessert + pain
                            </p>
                            <p style={{fontSize: "1.2vw", textDecoration: "underline"}}>
                                14.35€ TTC
                            </p>
                            <p style={{fontSize: "1.2vw", fontWeight: "bold"}}>
                                10.20€ après aides fiscales                            
                            </p>
                        </div>
                        <div class="col-2" style={{border: "3px solid #F6EDD9", borderRadius: "5px", textAlign: "center", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "20%"}}>
                            <img src="/logo_v3_vert.png" style={{width: "30%", marginTop: "1.3vw", marginBottom: "1.3vw"}}/>
                            <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>Supplément potage</h3>
                            <p style={{fontSize: "1.2vw", textDecoration: "underline"}}>
                                1.70€ TTC
                            </p>
                            <p style={{fontSize: "1.2vw", fontWeight: "bold"}}>
                                1.20€ après aides fiscales                            
                            </p>
                        </div>
                        <div class="col-2" style={{border: "3px solid #F6EDD9", borderRadius: "5px", textAlign: "center", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "20%"}}>
                            <img src="/logo_v3_vert.png" style={{width: "30%", marginTop: "1.3vw", marginBottom: "1.3vw"}}/>
                            <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>Déjeuner complet + dîner léger</h3>
                            <p style={{fontSize: "1.2vw"}}>
                                Déjeuner: entrée + plat + garniture +fromage + dessert + pain<br/><br/>
                                Dîner: potage + fromage et dessert                          
                            </p>
                            <p style={{fontSize: "1.2vw", textDecoration: "underline"}}>
                                19.10€ TTC
                            </p>
                            <p style={{fontSize: "1.2vw", fontWeight: "bold"}}>
                                13.60€ après aides fiscales                            
                            </p>
                        </div>
                        <div class="col-2" style={{border: "3px solid #F6EDD9", borderRadius: "5px", textAlign: "center", boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "20%"}}>
                            <img src="/logo_v3_vert.png" style={{width: "30%", marginTop: "1.3vw", marginBottom: "1.3vw"}}/>
                            <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>Formule journée</h3>
                            <p style={{fontSize: "1.2vw"}}>
                                2 menus complets                            
                            </p>
                            <p style={{fontSize: "1.2vw", textDecoration: "underline"}}>
                                23.75€ TTC
                            </p>
                            <p style={{fontSize: "1.2vw", fontWeight: "bold"}}>
                                16.90€ après aides fiscales                            
                            </p>
                        </div>
                    </div>
                    <div class="row align-items-center" style={{justifyContent: "space-around", marginTop: "3vw", position: "relative", marginBottom: "3vw"}}>
                        <div class="col" style={{textAlign: "right"}}>
                            <button className="menu_button"><a href="/menus.pdf" open target="_blank" style={{textDecoration: "unset", color: "white"}}>Découvrir le menu</a></button>
                        </div>
                        <div class="col" style={{textAlign: "left", alignItems: "center"}}>
                            <a className="contact_link" href="/#scrollspyContact">Contactez-nous</a>
                        </div>
                    </div>
                    <div class="row" style={{padding: "1.5vw", textAlign: "center"}}>
                        <h2 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw", color: "#1B7033"}}>
                            Formule très <span style={{color: "#9999CC"}}>souple</span> sans engagement dans la durée.<br/>
                            Variez selon vos <span style={{color: "#9999CC"}}>besoins</span>.<br/>
                            N’hésitez pas, contactez nous, nous chercherons ensemble la formule qui vous convient.
                        </h2>
                    </div>
                    <div class="container-fluid" style={{padding: "unset"}}>
                        <div class="row justify-content-center" className="footer stick">
                            <p className="opinion">Votre avis compte pour nous : <a className="opinion" href="https://g.page/r/CR4X4DmdzBGeEAI/review" target="_blank">Donnez le nous</a></p>
                            <p className="mentions">Copyright © Les lutins gourmets 2021 – Tous droits réservés<br/> Immatriculation au RCS, numéro 900 848 383 R.C.S. Annecy</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default repasPage