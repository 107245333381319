import React from "react";
import emailjs from 'emailjs-com'

class Contact extends React.Component {

    state = {
        name: '',
        firstname: '',
        email: '',
        num: '',
        message: ''
    }

    sendEmail(e) {
        e.preventDefault();
        const { name, firstname, email, num, message } = this.state

        if (email.indexOf('@') !== -1 && name !== '' && firstname !== '' && message !== '' && num !== '') {
            emailjs.sendForm('smtpmail', 'template_2srqwft', e.target, 'user_X2A3LvLtaoQuxjL5bkwk0')
            .then((result) => {
                console.log(result.text);
                this.resetForm()
                alert("Votre message à été envoyé");
            }, (error) => {
                console.log(error.text);
            });
        } else {
            window.alert('Impossible d\'envoyer le message :\nUn ou plusieurs champs sont vides ou incorect')
        }
    }

    resetForm() {
        this.setState({
            name: '',
            firstname: '',
            email: '',
            num: '',
            message: ''
      })
    }

    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    render() {
        return (
            <div class="col-6">
                <h2 style={{color: "#1B7033", fontFamily: "raleway", fontSize: "2.5vw"}}>Nous contacter</h2>
                <form class="form-group" style={{ backgroundColor: "#9999CC", padding: "2vw", borderRadius: "20px"}} onSubmit={this.sendEmail.bind(this)}>
                    <input onChange={this.handleChange.bind(this, 'name')} style={{marginBottom: "1vw", fontSize: "1.2vw", width: "20vw"}} type="name" name="user_name" class="form-control" id="lastName" placeholder="Entrez votre nom" value={this.state.name}></input>
                    <input onChange={this.handleChange.bind(this, 'firstname')} style={{marginBottom: "1vw", fontSize: "1.2vw", width: "20vw"}} type="name" name="user_firstname" class="form-control" id="firstName" placeholder="Entrez votre prénom" value={this.state.firstname}></input>
                    <input onChange={this.handleChange.bind(this, 'email')} style={{marginBottom: "1vw", fontSize: "1.2vw", width: "30vw"}} type="email" name="user_email" class="form-control" id="mail" aria-describedby="emailHelp" placeholder="Entrez votre E-mail" value={this.state.email}></input>
                    <input onChange={this.handleChange.bind(this, 'num')} style={{marginBottom: "1vw", fontSize: "1.2vw", width: "20vw"}} type="text" name="user_num" class="form-control" id="num" aria-describedby="numHelp" placeholder="Entrez votre numéro" value={this.state.num}></input>
                    <textarea onChange={this.handleChange.bind(this, 'message')} style={{marginBottom: "1vw", fontSize: "1.2vw"}} class="form-control" name="message" id="exampleFormControlTextarea1" rows="10" placeholder="Entrez votre message" value={this.state.message}></textarea>
                    <button style={{ fontFamily: "raleway", backgroundColor: "#1B7033", fontSize: "1.2vw"}} type="submit" class="btn btn-primary">Envoyer</button>
                </form>
            </div>
        )
    }
}

export default Contact;