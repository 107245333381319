import './App.css';
import HomePage from './pages/homepage';
import servicePage from './pages/servicePage';
import infosPage from './pages/infosPage';
import repasPage from './pages/repasPage';
import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom'

class App extends React.Component {
  render() {
    return (
      <Router basename="/">
        <Route exact path="/" component={HomePage}/>
        <Route path="/autres_services" component={servicePage}/>
        <Route path="/portage_de_repas" component={repasPage}/>
        <Route path="/infos" component={infosPage}/>
      </Router>
    );
  }
}

export default App;
