import React from "react";
import Carousel from 'react-bootstrap/Carousel'

class CarouselCustom extends React.Component {
    render() {
        return(
            <div style={{padding: "6vw"}}>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="terroir.jpg"
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2.5vw", textShadow: "1px 1px 1px rgba(0,0,0,.5)"}}>Une cuisine du terroir</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="potage.jpg"
                            alt="Second slide"
                        />

                    <Carousel.Caption>
                        <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2.5vw", textShadow: "1px 1px 1px rgba(0,0,0,.5)"}}>Des potages et de bouillons préparés comme à la maison</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="salade.jpg"
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2.5vw", textShadow: "1px 1px 1px rgba(0,0,0,.5)"}}>Des entrées confectionnées avec des produits frais</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="fromage.jpg"
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2.5vw", textShadow: "1px 1px 1px rgba(0,0,0,.5)"}}>Des fromages authentiques à la coupe</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="tarte.jpg"
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3 style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "2.5vw", textShadow: "1px 1px 1px rgba(0,0,0,.5)"}}>Des gâteaux gourmands réalisés par nos chefs</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>    
        )
    }
}

export default CarouselCustom