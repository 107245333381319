import React from 'react';

class NavBar extends React.Component {
    render() {
        const style = {
            background: '#9999CC',
            border: 'none',
            fontSize: '1.5vw',
            align: 'right',
            color: 'white',
            marginRight: '2vw'
          };
        const styleLink = {
            fontSize: '1.4vw',
            color: '#9999CC',
            textDecoration: 'underline',
            fontWeight: 'bold'
        }
      return (
        <nav style={{fontFamily: 'Raleway'}} class="navbar navbar-collapse navbar-light bg-light shadow-sm mb-2 bg-white rounded">
            <a style={{fontSize: '2vw', color: '#1B7033'}} class="navbar-brand text-center" href="/">
                <img style={{marginRight: '15%', marginLeft: '1.5vw', height: "5vw"}} src="/logo_v4.png" class="d-inline-block img-fluid" alt=""/>
                Les Lutins Gourmets
            </a>
            <a style={styleLink} type="submit" href="/portage_de_repas">nos repas</a>
            <a style={styleLink} type="submit" href="/autres_services">nos services</a>
            <a style={styleLink} type="submit" href="/infos">infos pratiques</a>
            <a style={style} class="btn btn-outline-success my-2 my-sm-0" type="submit" href="/#scrollspyContact">contact</a>
        </nav>
      ) 
    }
}

export default NavBar;