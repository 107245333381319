import React from "react";
import '../css/footer.css'

class Footer extends React.Component {
    render() {
        return (
            <div class="container-fluid" style={{padding: "unset"}}>
                <div class="row justify-content-center" className="footer">
                    <p className="opinion">Votre avis compte pour nous : <a className="opinion" href="https://g.page/r/CR4X4DmdzBGeEAI/review" target="_blank">Donnez le nous</a></p>
                    <p className="mentions">Copyright © Les lutins gourmets 2021 – Tous droits réservés<br/> Immatriculation au RCS, numéro 900 848 383 R.C.S. Annecy</p>
                </div>
            </div>
        )
    }
}

export default Footer;