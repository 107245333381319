import React from "react";

class Header extends React.Component {
    render() {
        const style = {
            background: '#9999CC',
            border: 'none',
            fontSize: '1.5vw',
            align: 'right',
            color: 'white',
            marginRight: '2vw',
            fontFamily: 'raleway'
        }
        
        return (
            <div class="container-fluid justify-content-between">
                <div class="row">
                    <div class="col-7" style={{backgroundColor: "", paddingLeft: "3vw", textAlign: "left"}}>
                        <h1 class="display-1" style={{fontSize: "5.2vw", fontFamily: "raleway", fontWeight: "bold", marginTop: "1vw", color: '#1B7033', lineHeight: "7vw"}}>Portage de repas à domicile<span style={{color: "#9999CC"}}><br/>un service </span>sur-mesure</h1>
                            <p style={{fontFamily: "raleway", fontWeight: "bold", fontSize: "1.2vw", marginBottom: "unset"}}>
                        Une perte d’autonomie, un problème de santé, un handicap<br/>
                        ne vous permettent pas de préparer vos repas ?<br/>
                        Les lutins gourmets vous accompagnent.
                            </p>
                            <p style={{fontFamily: "raleway", fontSize: "1vw", marginTop: "1vw", marginBottom: "unset"}}>
                        Notre vocation, prendre soin de chaque personne avec bienveillance<br/>
                        afin que chaque repas soit pour elle un moment de plaisir simple et savoureux.
                        <br/><br/>
                        Une cuisine traditionnelle, des plats mijotés. Nous avons coeur de préserver<br/>
                        la santé de nos aînés. Le bien-être avec des produits de qualité.
                            </p>
                            <div style={{marginTop: "2vw"}} class="row text-center justify-content-start">
                            <div class="col-5">
                                <a style={style} class="btn btn-outline-success my-2 my-sm-0" type="submit" href="#scrollspyContact">nous contacter</a>
                            </div>
                            <div class="col-5">
                                <a style={{fontFamily: "raleway", color: "#9999CC", fontSize: "1.5vw", textAlign: "center"}} href="/autres_services">découvrir nos services</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-5">
                        <img alt="headerFood" style={{width: "100%"}} src="/header-food.png"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;