import React from "react";

class Livraison extends React.Component {
    render() {
        return (
            <div class="container-fluid" style={{backgroundColor: "#F6EDD9"}}>
                <div class="row" style={{paddingTop: "2vw"}}>
                    <h2 style={{fontFamily: "raleway", fontSize: "3vw", color: "#9999CC", marginBottom: "2vw"}}>La livraison de repas en 3 étapes</h2>
                </div>
                <div class="row align-items-center">
                    <div class="col-sm">
                        <h3 style={{color: "#9999CC", fontFamily: "raleway", fontSize: "3vw", fontWeight: "bold"}}>.1</h3>
                        <img alt="cooker" style={{height: "18vw"}} class="img-fluid" src="/cooker.png"/>
                        <p style={{fontFamily: "raleway", color: "#1B7033", fontSize: "1.5vw", marginTop: "1vw"}}>Choix du menu</p>
                    </div>
                    <div class="col-sm">
                        <img alt="cooker" style={{height: "3.8vw"}} class="img-fluid" src="/arrow.png"/>
                    </div>
                    <div class="col-sm">
                        <h3 style={{color: "#9999CC", fontFamily: "raleway", fontSize: "3vw", fontWeight: "bold"}}>.2</h3>
                        <img alt="delivery" style={{height: "18vw"}} class="img-fluid" src="/delivery.png"/>
                        <p style={{fontFamily: "raleway", color: "#1B7033", fontSize: "1.5vw", marginTop: "1vw"}}>Livraison de votre repas chez vous</p>
                    </div>
                    <div class="col-sm">
                        <img alt="arrow" style={{height: "3.8vw"}} class="img-fluid" src="/arrow.png"/>
                    </div>
                    <div class="col-sm">
                        <h3 style={{color: "#9999CC", fontFamily: "raleway", fontSize: "3vw", fontWeight: "bold"}}>.3</h3>
                        <img alt="eat" style={{height: "18vw"}} class="img-fluid" src="/eat.png"/>
                        <p style={{fontFamily: "raleway", color: "#1B7033", fontSize: "1.5vw", marginTop: "1vw"}}>Dégustez !</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Livraison;