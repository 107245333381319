import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import '../css/services.css'

class servicePage extends React.Component {
    render() {
        return (
            <div>
                <NavBar/>
                <div class="container-fluid" style={{padding: "unset"}}>
                    <div class="row" style={{width: "100%"}}>
                        <div class="col-6" style={{padding: "5vw", fontFamily: "raleway"}}>
                            <h1 style={{fontWeight: "bold", color: "#1B7033", fontSize: "6.5vw", marginBottom: "1.5vw"}}>Faciliter votre quotidien</h1>
                            <p style={{fontSize: "1.4vw", marginBottom: "2vw"}}>
                                Pour une vie plus facile<br/>
                                nous vous apportons une aide,<br/>
                                une écoute bienveillante adaptée à vos besoins.
                            </p>
                            <div class="row" style={{paddingLeft: "1vw", width: "100%"}}>
                                <div class="col-5" style={{width: "20vw"}}>
                                    <a className="services_button" href="#scrollspyServices">voir les services</a>
                                </div>
                                <div class="col-5" style={{}}>
                                    <a style={{color: "#9999cc", fontSize: "1.2vw"}} href="/#scrollspyContact">nous contacter</a>
                                </div>
                            </div>
                        </div>
                        <img src="/handyman.png" alt="handyman" style={{width: "50%", paddingRight: "unset"}}/>
                    </div>
                    <div class="row" style={{placeItems: "center", marginTop: "4vw", width: "100%"}}>
                        <div class="col-9">
                            <div style={{backgroundColor: "#9999cc", height: "6px", borderRadius: "5px", marginLeft: "4.5vw"}}/>
                        </div>
                        <div class="col-3" style={{textAlign: "center"}}>
                            <h2 style={{fontFamily: "raleway", fontWeight: "bold", color: "#1b7033", fontSize: "3vw"}}>Nos services</h2>
                        </div>
                    </div>
                    <div class="row justify-content-md-center align-items-center" style={{marginTop: "2.5vw", width: "100%", placeContent: "center"}} id="scrollspyServices">
                        <div class="col-4" style={{}}>
                            <img src="/repas_pic.png" style={{width: "100%"}}/>
                        </div>
                        <div class="col-4">
                            <h3 style={{fontFamily: "raleway", color: "#1b7033", fontWeight: "bold", fontSize: "3.5vw"}}>Portage de repas</h3>
                            <p style={{fontSize: "1.5vw", marginBottom: "2.5vw", marginTop: "1.5vw"}}>
                                Une perte d'autonomie, un problème de santé,<br/>
                                un handicap, ne vous permettent pas<br/>
                                de préparer vos repas ?
                            </p>
                            <a style={{backgroundColor: "#9999cc", padding: "0.8vw", fontSize: "1.2vw", borderRadius: "5px", color: "white", textDecoration: "unset"}} href="/portage_de_repas">voir les repas</a>
                        </div>
                    </div>
                    <div class="row justify-content-md-center align-items-center" style={{marginTop: "6vw", width: "100%", placeContent: "center"}}>
                        <div class="col-5">
                            <h3 style={{fontFamily: "raleway", color: "#9999cc", fontWeight: "bold", fontSize: "3.5vw", textAlign: "right"}}>Ménage et repassage</h3>
                            <p style={{fontSize: "1.5vw", marginBottom: "2.5vw", marginTop: "1.5vw", textAlign: "right"}}>
                                Besoin d'un grand nettoyage de printemps, rangement<br/>
                                des armoires, linge, courses, vitres, repassage, nettoyage<br/>
                                avant retour d'hospitalisation etc...<br/>
                                Nous pouvons intervenir.<br/>
                                <span style={{fontSize: "2.5vh"}}>25€ / heure TTC | <span style={{fontWeight: "bold"}}>12.50€ / heure après aides fiscales</span></span>
                            </p>
                        </div>
                        <div class="col-4" style={{}}>
                            <img src="/ménage.png" style={{width: "100%"}}/>
                        </div>
                    </div>
                    <div class="row justify-content-md-center align-items-center" style={{marginTop: "6vw", width: "100%", placeContent: "center"}}>
                        <div class="col-4" style={{}}>
                            <img src="/admin.png" style={{width: "100%"}}/>
                        </div>
                        <div class="col-6">
                            <h3 style={{fontFamily: "raleway", color: "#1b7033", fontWeight: "bold", fontSize: "3vw"}}>Assistance administrative & informatique</h3>
                            <p style={{fontSize: "1.5vw", marginBottom: "2.5vw", marginTop: "1.5vw"}}>
                                La gestion administrative du quotidien, les relations avec différents organismes
                                ne sont pas toujours simples. Aussi Les Lutins Gourmets vous accompagnent, de manière ponctuelle
                                dans ces démarches.<br/>
                                Nous pouvons, également, vous servir d'intermédiaire auprès des administrations.<br/>
                                <span style={{fontSize: "2.5vh"}}>26€ / heure TTC | <span style={{fontWeight: "bold"}}>13€ / heure après aides fiscales</span></span>
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-md-center align-items-center" style={{marginTop: "6vw", width: "100%", placeContent: "center"}}>
                        <div class="col-5">
                            <h3 style={{fontFamily: "raleway", color: "#9999cc", fontWeight: "bold", fontSize: "3.5vw", textAlign: "right"}}>Bricolage & jardinage</h3>
                            <p style={{fontSize: "1.5vw", marginBottom: "2.5vw", marginTop: "1.5vw", textAlign: "right"}}>
                                Pour vos petits travaux de bricolage et de jardinage, Les 
                                Lutins Gourmets peuvent intervenir ponctuellement.
                                Vous avez besoin de déboucher un évier, déplacer un meuble,
                                changer un lustre, ratisser le jardin etc ... .<br/>
                                <span style={{fontSize: "2.5vh"}}>28€ / heure TTC | <span style={{fontWeight: "bold"}}>14€ / heure après aides fiscales</span></span>
                            </p>
                        </div>
                        <div class="col-4" style={{}}>
                            <img src="/bricolage.png" style={{width: "100%"}}/>
                        </div>
                    </div>
                    <h3 style={{textAlign: "center", marginTop: "3vw", fontSize: "3vw"}}>
                        Bénéficiez d'une rédution fiscale de 50% : <a href="/infos">en savoir plus</a>
                    </h3>
                    <div class="row" style={{marginTop: "1.5vw", placeItems: "center", marginTop: "4vw", width: "100%"}}>
                        <div class="col-3" style={{textAlign: "right"}}>
                            <h2 style={{fontFamily: "raleway", fontWeight: "bold", color: "#1b7033", fontSize: "3vw"}}>Fonctionnement</h2>
                        </div>
                        <div class="col-9">
                            <div style={{backgroundColor: "#9999cc", height: "6px", borderRadius: "5px", marginRight: "4.5vw"}}/>
                        </div>
                    </div>
                    
                    <h3 style={{textAlign: "center", marginBottom: "3vw", marginTop: "3vw", fontWeight: "lighter", fontSize: "3vw"}}><span style={{fontWeight: "bold"}}>1. Les Lutins Gourmets se déplacent chez vous</span> pour évaluer vos besoins et attentes</h3>
                    <h3 style={{textAlign: "center", marginBottom: "3vw", fontWeight: "lighter", fontSize: "3vw"}}><span style={{fontWeight: "bold"}}>2. Un devis personalisé,</span> est rédigé pour évaluer vos besoins et attentes</h3>
                    <h3 style={{textAlign: "center", marginBottom: "3vw", fontWeight: "bold", fontSize: "3vw"}}>3. Réalisation de la prestation</h3>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default servicePage