import React from "react";
import NavBar from "../components/navbar";
import Accordion from 'react-bootstrap/Accordion'
import CarouselCustom from "../components/carouselCustom";
import Footer from "../components/footer";
import '../css/infos.css'

class infosPage extends React.Component {
    render() {
        const title = {
            textAlign: "center",
            fontFamily: "raleway",
            fontWeight: "bolder",
            fontSize: "2.5vw"
        }
        const title_next = {
            textAlign: "center",
            fontFamily: "raleway",
            fontWeight: "bolder",
            fontSize: "2.5vw",
            marginTop: "5vw"
        }

        const under_title = {
            textAlign: "center",
            fontFamily: "raleway",
            fontSize: "2vw"
        }

        const content = {
            textAlign: "center",
            fontFamily: "raleway",
            fontSize: "1.2vw",
            marginTop: "2vw"
        }
        return (
            <div>
                <NavBar/>
                <div class="container-fluid" style={{padding: "unset"}}>
                    <img src="/senior_cut.png" alt="senior_cut" style={{width: "100%"}}/>
                    <div class="row " style={{marginTop: "4vw", width: "100%"}}>
                        <div class="col-4" style={{textAlign: "center"}}>
                            <a href="#fiscale" style={{color: "#1b7033", fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>Aides fiscales</a>
                        </div>
                        <div class="col-4" style={{textAlign: "center", fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>
                            <a href="#apa" style={{color: "#1b7033"}}>Allocations Personalisée d'Autonomie</a>
                        </div>
                        <div class="col-4" style={{textAlign: "center", fontFamily: "raleway", fontWeight: "bold", fontSize: "2vw"}}>
                            <a href="#nutrition" style={{color: "#1b7033"}}>Nutrition</a>
                        </div>
                    </div>
                    <div class="row" style={{marginTop: "1.5vw", placeItems: "center", marginTop: "4vw", width: "100%", marginBottom: "4vw"}}>
                        <div class="col-3" style={{textAlign: "right"}}>
                            <h2 style={{fontFamily: "raleway", fontWeight: "bold", color: "#1b7033", fontSize: "3vw"}}>Aides fiscales</h2>
                        </div>
                        <div class="col-9">
                            <div style={{backgroundColor: "#9999cc", height: "6px", borderRadius: "5px", marginRight: "4.5vw"}}/>
                        </div>
                    </div>
                    <h2 id="fiscale" className="titre">Pour le portage de repas à domicile.</h2>
                    <h3 className="under_title">Des aides fiscales sur la moitié des frais de livraison</h3>
                    <p className="content">
                        Vous pouvez bénéficier d'une réduction ou d'un crédit d'impôt correspondant à la moitié des frais<br/>
                        de livraison de vos repas, quelle que soit votre situation fincancière, sous la condition<br/>
                        (depuis l'automne 2019) de faire appel à une société agréée services à la personne.<br/>
                        Les Lutins Gourmets sont agréés sour le numéro: SAP900848383.
                    </p>
                    <h2 id="fiscale" className="titre next">Pour les prestations de service à la personne</h2>
                    <p className="content">
                        L'avantage fiscale prend la forme d'un crédit d'impôt sur le revenu égal à 50% des dépenses engagées<br/>
                        pour des prestations de services à la personne, dans le limite de 12 000€ par an (loi de finances 2017).<br/><br/>
                        Ce plafond est applicable pour toutes les activités de services à la personne, sauf pour :<br/>
                        <pre className="content"><span style={{fontWeight: "bold"}}>- Le petit jardinage: </span>     plafond limité à 5 000€.</pre>
                        <pre className="content"><span style={{fontWeight: "bold"}}>- L'assistance informatique et internet:</span>     plafond limité à 3 000€.</pre>
                        <pre className="content"><span style={{fontWeight: "bold"}}>- Le petit bricolage:</span>     plafond limité à 5 000€ (une intervention ne peut dépasser 2 heures).</pre><br/>
                        Le crédit d'impôt est déduit du montant de l'impôt dû. Si le montant de votre crédit d'impôt est supérieur<br/>
                        au montant de votre impôt sur le revenu, vous êtes remboursé du surplus ou de la totalité<br/>
                        (si vous êtes non imposable) par l'administration fiscale.<br/><br/>
                        Pour en savoir plus, consultez l'article <a href="https://www.impots.gouv.fr/portail/particulier/emploi-domicile" target="_blank">Emploi à domicile</a> sur le site <a href="https://www.impots.gouv.fr/portail/" target="_blank">www.impots.gouv.fr</a>
                    </p>
                    <div id="apa" class="row" style={{placeItems: "center", marginTop: "4vw", width: "100%"}}>
                        <div class="col-9">
                            <div style={{backgroundColor: "#9999cc", height: "6px", borderRadius: "5px", marginLeft: "4.5vw"}}/>
                        </div>
                        <div class="col-3" style={{textAlign: "center"}}>
                            <h2 style={{fontFamily: "raleway", fontWeight: "bold", color: "#1b7033", fontSize: "3vw"}}>A.P.A</h2>
                        </div>
                    </div>
                    <p className="content">
                        L'A.P.A (Allocation Personalisée d'Autonomie) peut servir à payer (en totalité ou en partie) les dépenses<br/>
                        nécessaires pour vous permettre de rester à votre domicile ("APA à domicile").<br/><br/>
                        Il s'agit d'une prestation délivrée par le Conseil Général, destinée à prendre en charge la perte d'autonomie<br/>
                        des personnes âgées vivant à domicile ou en établissement.
                    </p>
                    <Accordion style={{fontFamily: "raleway", marginRight: "6vw", marginLeft: "6vw"}}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Quels sont les bénéficiaires ?</Accordion.Header>
                                <Accordion.Body>
                                    <span style={{fontWeight: "bold"}}>Pour bénéficier de l'A.P.A, il faut satisfaire différentes conditions:</span><br/><br/>
                                    - être agé d'au moins 60 ans,<br/>
                                    - être en situation de perte d'autonomie correspondant aux GIR 1, 2, 3 ou 4 (voir ci-dessous)<br/>
                                    - résider de façon stable et régulière en France<br/><br/>
                                    GIR (Groupe Iso-Ressource) : Un GIR est une classification permettant de mesurer le degré de perte<br/>
                                    d'autonomie de la personne à l'aide d'une grille nationale d'évaluation de la dépendance (grille AGGIR).<br/><br/>
                                    La grille AGGIR comporte 6 GIR : le GIR 1 correspond au plus fort degré de perte d'autonomie<br/>
                                    physique et psychique, les GIR 5 et 6 correspondent aux personnes peu dépendantes ou valides.<br/><br/>
                                    L'attribution de l'A.P.A n'est pas liée à une condition de ressources mais fera évoluer le montant qui est donné.

                                </Accordion.Body>
                            </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Comment demander cette aide ?</Accordion.Header>
                                <Accordion.Body>
                                            Pour demander l'A.P.A, vous devez vous procurer un dossier de demande de l'A.P.A auprès des services<br/>
                                            du département proche de votre domicile, éventuellement en vous faisant aider par votre mairie ou<br/>
                                            son CCAS, ou d'un point d'information local dédié aux personnes agées.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Evaluation de la demande</Accordion.Header>
                                <Accordion.Body>
                                            Une fois le dossier complet, un membre de l'équipe médico-sociale du conseil départemental se rend à votre<br/>
                                            domicile pour faire <span style={{fontWeight: "bold"}}>un bilan de votre situation et de vos besoins.</span> Vous pouvez vous faire<br/>
                                            accompagner lors de cette visite (proche, médecin...). Vous recevez tous les conseils et informations<br/>
                                            et cet échange permet l'élaboration d'une proposition de plan d'aide.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Décision de l'attribution de l'A.P.A</Accordion.Header>
                                <Accordion.Body>
                                            En cas d'accord, à partir des critères définis par la Métropole et dans la limite des plafonds<br/>
                                            nationaux, la <span style={{fontWeight: "bold"}}>décision d'attribution vous sera notifiée par arrêté du Président de la Métropole.</span><br/><br/>
                                            Cet arrêté reprend l'ensemble de vos droits et obligations. En cas de rejet, un arrêté de rejet à<br/>
                                            l'A.P.A vous sera adressé. Vous pourrez alors solliciter d'autres organismes (caisse de retraite, mutuelle)<br/>
                                            susceptibles de vous aider en fonction de votre situation. 
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Montant de l'aide</Accordion.Header>
                                <Accordion.Body>
                                            Le <span style={{fontWeight: "bold"}}>montant de votre allocation</span> dépend :<br/><br/>
                                            - de votre degré d'autonomie,<br/>
                                            - de votre besoin d'aide à domicile ou du "tarif dépendance" de l'établissement d'accueil,<br/>
                                            - du montant de vos ressources<br/><br/>
                                            Quelle que soit votre situation, le montant de votre A.P.A ne peut pas dépasser les plafonds nationaux<br/>
                                            qui sont déterminés en fonction de votre degré d'autonomie, et réévalués chaque année.<br/><br/>
                                            <span style={{fontWeight: "bold"}}>Votre participation financière</span><br/><br/>
                                            Elle varie de 0 à 90% de votre plan d'aide, en fonction de vos ressources.
                                </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div id="nutrition" class="row" style={{marginTop: "1.5vw", placeItems: "center", marginTop: "4vw", width: "100%", marginBottom: "4vw"}}>
                        <div class="col-3" style={{textAlign: "right"}}>
                            <h2 style={{fontFamily: "raleway", fontWeight: "bold", color: "#1b7033", fontSize: "3vw"}}>Nutrition</h2>
                        </div>
                        <div class="col-9">
                            <div style={{backgroundColor: "#9999cc", height: "6px", borderRadius: "5px", marginRight: "4.5vw"}}/>
                        </div>
                    </div>
                    <CarouselCustom/>
                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Alimentation</label>
                            <img src="./rhumatisme.png" width="100px" onClick={()=> window.open("./rhumatisme.png", "_blank")}></img>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Alimentation</label>
                            <img src="./rhumatisme2.png" width="100px" onClick={()=> window.open("./rhumatisme2.png", "_blank")}></img>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Conservation</label>
                            <img src="./conservation.png" width="100px" onClick={()=> window.open("./conservation.png", "_blank")}></img>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Nutrition</label>
                            <img src="./nutrition.png" width="100px" onClick={()=> window.open("./nutrition.png", "_blank")}></img>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Hydratation</label>
                            <img src="./Hydratation.png" width="100px" onClick={()=> window.open("./Hydratation.png", "_blank")}></img>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label>Métiers</label>
                            <img src="./métiers.png" width="100px" onClick={()=> window.open("./métiers.png", "_blank")}></img>
                        </div>
                    </div>
                    <div id="apa" class="row" style={{placeItems: "center", marginTop: "4vw", width: "100%"}}>
                        <div class="col-9">
                            <div style={{backgroundColor: "#9999cc", height: "6px", borderRadius: "5px", marginLeft: "4.5vw"}}/>
                        </div>
                        <div class="col-3" style={{textAlign: "center"}}>
                            <h2 style={{fontFamily: "raleway", fontWeight: "bold", color: "#1b7033", fontSize: "3vw"}}>Livraison</h2>
                        </div>
                    </div>
                    <div class="row" style={{width: "100%", fontFamily: "raleway", marginTop: "3vw", marginBottom: "3vw"}}>
                        <div class="col-8">
                            <p className="delivery">
                            Selon vos choix, notre livreur vous apporte vos repas,
                            les places dans votre réfrigérateur ou vous les remets en main propre.
                            L'occasion de voir avec vous si tout va bien,de faire un point sur vos besoins.
                            </p>
                        </div>
                        <div class="col-4" style={{justifyContent: "flex-end"}}>
                            <img style={{width: "100%"}} src="/senior_help.jpg"/>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default infosPage