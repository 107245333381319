import React from "react";

class Services extends React.Component {
    render() {
        return (
            <div id="scrollspyServices" class="container-fluid">
                <div class="row">
                    <h2 style={{color: "#1B7033", fontFamily: "raleway", fontSize: "3vw"}} >Découvrir nos services</h2>
                </div>
                <div class="row" style={{marginTop: "2vw"}}>
                    <div class="col-5">
                        <img alt="repas" style={{height: "18vw"}} class="img-fluid" src="/repas_pic.png"/>
                        <p style={{marginBottom: "unset", fontFamily: "raleway", fontSize: "1.3vw"}}>Livraison de repas</p>
                    </div>
                    <div class="col-7" style={{ backgroundColor: "#F6EDD9", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", padding: "2vw"}}>
                        <h3 style={{color: "#9999CC", textAlign: "right", fontFamily: "raleway", fontSize: "2vw", fontWeight: "bold"}}>Les lutins gourmets vous simplifient la vie !</h3>
                        <p style={{textAlign: "right", fontFamily: "raleway", fontSize: "1.3vw", marginTop: "1.1vw", marginBottom: "unset"}}>
                        En fonction de vos besoins ou des demandes formulées par votre entourage<br/>
                        nous vous proposons en plus du portage de repas, différents types<br/>
                        d’interventions pour vous faciliter le quotidien et vous permettre de<br/>
                        conserver un lien social. Quelques heures de ménages, des<br/>
                        petits bricolages, jardinage, une assiatance administrative et/ou informatique...<br/>
                        Nous étudions vos besoins et trouvons avec vous la solution la plus adaptée.
                        </p>
                    </div>
                </div>
                <div class="row" style={{marginTop: "3vw", marginBottom: "3vw"}}>
                    <div class="col-4">
                        <img alt="ménage" style={{height: "15vw"}} class="img-fluid" src="/ménage.png"/>
                        <p style={{marginBottom: "unset", fontFamily: "raleway", fontSize: "1.3vw"}}>Ménage & repassage</p>
                    </div>
                    <div class="col-4">
                        <img alt="bricolage" style={{height: "15vw"}} class="img-fluid" src="/bricolage.png"/>
                        <p style={{marginBottom: "unset", fontFamily: "raleway", fontSize: "1.3vw"}}>Petits bricolages & Jardinage</p>
                    </div>
                    <div class="col-4">
                        <img alt="admin" style={{height: "15vw"}} class="img-fluid" src="/admin.png"/>
                        <p style={{marginBottom: "unset", fontFamily: "raleway", fontSize: "1.3vw"}}>Assistance administrative<br/>& informatique</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Services;